import Header from "../header/Header";
import Partners from "../partners/PartnersDEMO";
import Services from "../services/Services";
import Subscribe from "../subscribe/Subscribe";




export default function Main(props) {
   
    

    return (
        <>
        
            <>
                <Header />
                <Services />
                <Subscribe />
                <Partners />
            </>
        
       </>
    )
}