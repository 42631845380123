import projectsGallery from '../../assets/images/projects/projectsGallery';
import Project from './Project';
import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { motion as m } from 'framer-motion';


export default function Projects() {

    
    const [projects, setProjects] = useState(
        projectsGallery
    )

    const [pageNumber, setPageNumber] = useState(0);

    const imagesPerPage = 6;

    const pagesVisited = pageNumber * imagesPerPage

    const pageCount = Math.ceil(projects.length / imagesPerPage);

    let sliced = projects.slice(pagesVisited, pagesVisited + imagesPerPage);
    
    console.log(sliced.length);

    const displayImages = sliced.map(
        (project, index) => {
            console.log(index)

            return (
                <>
                <Project background={project.background} gallery={project.gallery} description={project.description} location={project.location} year={project.year}/>
                </>
            )
        }
    )

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    }

    return (
        <>
        <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
        transition={{duration: 0.75, ease: "easeOut"}} className="grid grid-cols-3 px-[150px] pt-[150px] pb-[50px] gap-2 xl:p-[100px] lg:grid-cols-2 sm:grid-cols-1 xs:p-[20px] xs:pt-[60px]" >
            { projects && displayImages}
            
        </m.div>
        {
            (projects.length <= imagesPerPage) ? 
            "" : 
            <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
            transition={{duration: 0.75, ease: "easeOut"}} className="flex px-[150px] pt-[30px] pb-[100px] justify-center xs-helper:px-[20px]">
                <ReactPaginate 
                previousLabel={"Previous"} 
                nextLabel={"Next"} 
                pageCount={pageCount} 
                onPageChange={changePage} 
                containerClassName={"paginationBttns"} 
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                breakLabel="..."
                />
            </m.div>
        }
        
       
        </>
    )
}