import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PartnersLogos from '../../assets/images/partners/PartnersLogos';




export default function Partners() {

const responsive = {
  largeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 3000, min: 2000 },
    items: 8
  },
  desktop: {
    breakpoint: { max: 1999, min: 1280 },
    items: 6
  },
  laptop: {
    breakpoint: { max: 1279, min: 1024 },
    items: 4
  },
  tablet: {
    breakpoint: { max: 1023, min: 640 },
    items: 3
  },
  mobile: {
    breakpoint: { max: 639, min: 0 },
    items: 1
  }
};

return (
    <div className="p-[150px] lg:p-[130px] md:p-[80px] sm:p-[100px] xs:p-[50px]">
        <Carousel responsive={responsive}>
        
                <div className="w-[75%]">
                   <img className="w-[100%]" src={PartnersLogos.abd} alt="abc"/>
                </div>
                <div className="w-[75%]">
                    <img className="w-[100%]" src={PartnersLogos.dmf} alt="dmf"/>
                </div>
                <div className="w-[75%]">
                   <img className="w-[100%]" src={PartnersLogos.fpp} alt="fpp"/>
                </div>
                <div className="w-[75%]">
                    <img className="w-[100%]" src={PartnersLogos.kcMedia} alt="kc media"/>
                    </div>
                <div className="w-[75%]">
                  <img className="w-[100%]" src={PartnersLogos.minesu} alt="minesu"/>
                </div>
                <div className="w-[75%]">
                  <img className="w-[100%]" src={PartnersLogos.radler} alt="radler"/>
                </div>
                <div className="w-[75%]">
                    <img className="w-[100%]" src={PartnersLogos.royal} alt="royal"/>
                </div>
                <div className="w-[75%]">
                   <img className="w-[100%]" src={PartnersLogos.selecto} alt="selecto"/>
                </div>
       
        </Carousel>
</div>
 )
}