import ABD from '../partners/abc.jpg';
import dmf from '../partners/dmf-office.png';
import fpp from '../partners/fpp-logo600.png';
import selecto from '../partners/img-logo-selecto.png';
import radler from '../partners/index.jpg';
import minesu from '../partners/logo-dole-lijevo1112222.png';
import kcMedia from '../partners/logo-kc-media1600.png';
import royal from '../partners/ROYAL.png';

let partners = {
    'abd': ABD,
    'dmf': dmf,
    'fpp': fpp,
    'selecto': selecto,
    'radler': radler,
    'minesu': minesu,
    'kcMedia': kcMedia,
    'royal': royal
}

export default partners;