import founder from '../../assets/images/aboutUs/osnivac.jpg';
import aboutUs1 from '../../assets/images/aboutUs/aboutUs1.jpg';
import team from '../../assets/images/aboutUs/team.jpg';
import bim from '../../assets/images/aboutUs/bim.jpg';
import bim2 from '../../assets/images/aboutUs/bim2.jpg';
import bim3 from '../../assets/images/aboutUs/bim3.jpg';
import { motion as m } from 'framer-motion';

export default function AboutUs() {

    return (
        <>
        <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
        transition={{duration: 0.75, ease: "easeOut"}} className="grid grid-cols-2 gap-20 m-[150px] xl:m-[100px] xl:grid-cols-2 lg:gap-10 lg:grid-cols-1 xs:m-[30px]">
            
            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-1 lg:order-1">
                <div className="">
                    <h1 className="text-9xl text-slate-600 transform -rotate-90 origin-top-left absolute top-[550px] left-[110px] z-0 opacity-10 2xl:top-[400px] 2xl:text-8xl sm:text-7xl sm:left-16 xs:left-0">O nama</h1>
                    <h2 className="text-7xl font-medium z-10 relative xl:flex xl:items-end">CODE <span className="text-slate-600 font-medium text-5xl lg:text-4xl sm:text-4xl">Design </span></h2>
                    <h3 className="text-2xl text-slate-600 z-10 relative">Architecture & Project Management</h3>
                    <p className="text-lg mt-[80px] text-slate-600 z-10 relative xl:mt-[40px]">Osnivač</p>
                    <p className="text-5xl z-10 relative">Jasmin Huremović <span className="text-2xl text-slate-600 z-10 relative">dipl.ing.arh.</span></p>
                </div>
            </div>
            <div className="order-2 lg:order-2">
                <img className="w-[100%] object-fit" src={founder} alt="founder"/>
            </div>

            <div className="order-3 lg:order-4">
                <img className="w-[100%]" src={aboutUs1} alt="Work we do"/>
            </div>
            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-4 lg:order-3">
                <p>
                    Više od 10 godina iskustva u različitim oblastima građevinske industrije početkom 2018. godine pretočeno je u rad našeg arhitektonskog biroa.
                    Arhitektonski biro “CODE design d.o.o.” bavi se prije svega uslugama <b>arhitektonskog projektovanja, dizajnom enterijera, project managementom, nadzorom nad izvođenjem 
                    građevinskih radova, tehničkim savjetovanjem i konsaltingom.</b>
                </p>
            </div>

            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-5">
                <p>
                    Naš mladi tim nudi drugačiji i efikasniji pristup arhitekturi koji u skladu s savremenim trendovima vodi  klijenta od ideje do realizacije projekta.
                    Primjena savremenih metoda projektovanja, savremenih materijala, optimizacija projekata i usluge project managementa sastavni su dio arhitekture novog doba 
                    i u potpunosti su prilagođeni najnovijim svjetskim trendovima.
                </p>
            </div>
            <div className="order-6">
                <img className="w-[100%]" src={team} alt="team"/>
            </div>

            <div className="order-7 lg:order-8">
                <img className="w-[100%]" src={bim} alt="bim"/>
            </div>
            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-8 lg:order-7">
                <p>
                    Kroz inovativna rješenja, održivu i energetski efikasnu gradnju brinemo ne samo o dobrobiti naših klijenata nego i o zaštiti životne sredine.
                    Upotrebom <b>BIM</b>  sistema podižemo kvalitet projetovanja i gradnje Vaših objekta. <b>BIM (Building information modeling)</b> je najnapredniji koncept u 
                    projektovanju koji objedinjuje sve struke u građevinarstvu i arhitekturi.
                </p>
            </div>

            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-9">
                <p>Osnova <b>BIM</b> sistema je trodimenzionalni model objekta koji osim vizualne reprezentacije u sebi sadrži i  druge informacije kao što su geodetske koordinate, količine 
                materijala i elemenata, svojstva elementa (toplinska provodljivost, masa, čvrstoca…), detalji elemenata, cijene i  druge informacije potrebne za izradu projektne dokumentacije i
                izgradnju samog objekta.</p>
            </div>
            <div className="order-10 ">
                <img className="w-[100%]" src={bim2} alt="bim"/>
            </div>

            <div className="order-11 lg:order-12">
                <img className="w-[100%]" src={bim3} alt="bim"/>
            </div>
            <div className="flex flex-col justify-center items-center text-2xl text-slate-600 order-12 lg:order-11">
                <p>Danas se <b>BIM</b> sistem naziva i <b>5D modeliranje</b> jer osim 3D modela BIM model u sebi sadrži dodatne informacije te prilikom izgradnje objekta uz pomoć BIM sistema se 
                prate faze izgradnje, a kasnije i održavanje objekta.</p>
            </div>
        </m.div>
            
            
            
        
        </>
    )
}