import './App.css';
import './assets/icons/Icons';
import Projects from './components/projects/Projects';
import Footer from './components/footer/Footer';
import Main from './components/main/Main';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom';
import AboutUs from './components/aboutUs/aboutUs';
import Contact from './components/contact/Contact';
import { useEffect, useState, } from 'react';
import BarLoader from "react-spinners/BarLoader";
import { motion as m } from "framer-motion";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
  
};

function App() {

  const [loading, setLoading] = useState(false);
  

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false),
      2500
    )
   
    
  }, []);



  return (
    <>
    {
      loading ?
      <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
      transition={{duration: 0.75, ease: "easeOut"}} className="flex items-center justify-center h-screen">
      <BarLoader
        color={"#000000"}
        loading={loading}
        cssOverride={override}
        size={40}
      />
      </m.div>
      :  

      <HashRouter>
        <Navigation />
          <Routes>
            <Route exact path="/" element={<Main/>} />
            <Route path="/Projekti" element={<Projects />} />
            <Route path="/O nama" element={<AboutUs/>} />
            <Route path="/Kontakt" element={<Contact/>} />
          </Routes>
        <Footer />
      </HashRouter>
    }

    
   </>
  )
}

export default App;
