import ArchitecturePlan from "../services/architecture-plan-icon.png";
import InteriorDesign from "../services/interior-design.png";
import Consulting from "../services/consulting.png";
import Supervisor from "../services/supervisor.png";
import ProjectManagement from "../services/project-management.png";
import VisualizationService from "../services/analytics.png";
import SustainableConstruction from "../services/sustainable.png";
import BIM from "../services/bim.png";
import ServicesBackground from "../services/service-bg.jpg";

const images = {
    "arhitecturePlan": ArchitecturePlan,
    "interiorDesign": InteriorDesign,
    "consulting": Consulting,
    "supervisor": Supervisor,
    "projectManagement": ProjectManagement,
    "visualizaitonService": VisualizationService,
    "sustainableConstruction": SustainableConstruction,
    "bim": BIM,
    "services-bg": ServicesBackground
}

export default images;