import ServiceImages from "../../assets/images/services/ServicesImages";

export default function Services() {

    return (
        <section
        className="bg-services-bg bg-cover bg-fixed px-[150px] pt-[380px] pb-[150px] text-center 2xl:pt-[200px] xl:pt-[240px] xl:px-[100px] lg:px-[120px] md:pt-[250px] md:px-[140px] sm:px-[100px] xs:px-[20px] xs-helper:pt-[170px] xs-helper:pb-[100px]">
            <div className="grid grid-cols-4 gap-20 xl:gap-8 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 sm:flex sm:flex-col sm:items-center sm:justify-center">
                <div className="h-[300px]  pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%] sm:pt-[50px]">
                    <div className="h-[30%] mb-[20px] xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.arhitecturePlan} alt="Architecture plan"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Arhitektonsko projektovanje
                    </p>
                </div>
                <div className="h-[300px] pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%] sm:pt-[50px]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.interiorDesign} alt="Interior Design"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Dizajn enterijera
                    </p>
                </div>
                <div className="h-[300px]  pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.consulting} alt="Services and Consulting"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Tehničko savjetovanje i konsalting
                    </p>
                </div>
                <div className="h-[300px] pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.supervisor} alt="Supervisor"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Nadzor nad građevinskim radovima
                    </p>
                </div>
                <div className="h-[300px] pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%] sm:pt-[50px]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.projectManagement} alt="Project management"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Project managment
                    </p>
                </div>
                <div className="h-[300px] pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.visualizaitonService} alt="Visualization service"/>
                    </div>
                    <p className="text-xl text-slate-800 mt-[40px] xl:mt-[20px]">
                        Usluge vizualizacije objekata
                    </p>
                </div>
                <div className="h-[300px] pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%] sm:pt-[50px]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.sustainableConstruction} alt="Sustainable construction"/>
                    </div>
                    <p className="text-xl text-slate-800  mt-[40px] xl:mt-[20px]">
                        Održiva gradnja
                    </p>
                </div>
                <div className="h-[300px]  pt-[50px] px-[30px] flex flex-col items-center justify-start bg-slate-200 2xl:h-[250px] 2xl:pt-[30px] xl:h-[220px] xl:pt-[20px] sm:w-[70%]">
                    <div className="h-[30%] mb-[20px] 2xl:mb-[7px]">
                        <img className="h-[100%]" src={ServiceImages.bim} alt="bim"/>
                    </div>
                    <p className="text-xl text-slate-800  mt-[40px] xl:mt-[20px]">
                        BIM - Building Information Modeling
                    </p>
                </div>
            </div>
            
        </section>
    )
}