import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useEffect } from 'react'
import HeaderImage from '../../assets/images/header/scott-webb.jpg';
import { Link } from 'react-router-dom';
import { motion as m } from "framer-motion";


export default function Header() {

    const [btnArr, setBtnArr] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        setIsLoaded(true);
        console.log("Hello");
        
    }, []);

    console.log(isLoaded);
    return (
        <m.header initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: "easeOut"}} className="bg-black flex flex-col justify-center items-center h-[550px] relative xl:h-[450px] lg:h-[400px] lg-helper:h-[350px] md:h-[280px]" >
            <m.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: "easeOut"}} className="flex flex-col justify-center items-center absolute top-[50px]">
                <Link to="/Projekti"><button type="button" className="relative bg-cyan-600 hover:bg-cyan-500 w-[230px] h-[45px] text-white flex items-center justify-between rounded" onMouseEnter={() => setBtnArr(true)} onMouseLeave={() => setBtnArr(false)}><span className="ml-[30px]">Pretražite projekte</span><FontAwesomeIcon icon="arrow-right" className={`ml-[15px] text-xl absolute left-[70%] transition-all ${(btnArr) ? 'ml-[20px]' : ""}`}></FontAwesomeIcon></button></Link>
                    <m.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 0.75, ease: "easeOut"}} className="z-10 mt-[20px]">
                        <h1 className="text-7xl font-semibold tracking-wide mb-[10px] text-white lg:text-5xl lg:mb-[5px]">OTKRIJTE <span className="">SVIJET</span></h1>
                        <h1 className="text-7xl font-bold tracking-wide mb-[10px] text-black lg:text-5xl lg:mb-[5px]">NAŠIH</h1>
                        <h1 className="text-7xl font-bold tracking-wide mb-[10px] text-black lg:text-5xl lg:mb-[5px]">PROJEKATA</h1>
                    </m.div>
                <div className="w-[60vw] absolute top-[50%] md:w-[70vw] sm:w-[90vw] xs:w-[100vw] xs-helper:top-[60%]">
                    <img src={HeaderImage} className="w-full" alt="header image"/>
                </div>
            </m.div>
        </m.header>
    )
}
