import Map from '../../assets/images/contact/navigation/map.jpg';
import React, {useState,} from 'react';
import { motion as m } from 'framer-motion';
import { GoogleMap, useJsApiLoader, MarkerF, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};


const center = {
  lat: 44.530049306736885,
  lng: 18.692964870199837
};

export default function Contact() {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
  });

   const [mailerState, setMailerState] = useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            subject: "",
            message: "",
        }
    );
    
    function handleStateChange(e) {
        setMailerState((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }

     

    const submitEmail = async (e) => {
        e.preventDefault();
        console.log({ mailerState });
        const response = await fetch("https://api.code-design.ba/send-email", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify({ mailerState }),
        })
          .then((res) => res.json())
          .then(async (res) => {
            const resData = await res;
            console.log(resData);
            if (resData.status === "success") {
                alert("Message Sent");
            } else if (resData.status === "fail") {
                alert("Message failed to send");
            }
          })
          .then(() => {
            setMailerState({
                firstName: "",
                lastName: "",
                email: "",
                subject: "",
                message: "",
            });
          });
      };
    
    return (
        <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
        transition={{duration: 0.75, ease: "easeOut"}} id="contact" className="flex p-[150px] bg-slate-100 xl:p-[100px] lg-helper:flex-col  md:px-[75px] md:flex-col sm:px-[20px] sm:pt-[60px] ">
            <div className="w-[50%] mr-[80px] lg-helper:w-[100%] lg-helper:mb-[100px] md:w-[100%] md:mb-[100px]">
            <h2 className="text-5xl mb-[80px] text-slate-600">Kontaktirajte nas</h2>
                <form className="w-[100%]" onSubmit={submitEmail}>
                    <div className="flex flex-col">
                        <div className="flex justify-between items-start sm:flex-col">
                            <input name="firstName" type="text" placeholder='Ime' onChange={handleStateChange} value={mailerState.firstName} className="mb-[20px] bg-white p-[10px] w-[49%] rounded sm:w-[100%] border-[1px] border-solid border-slate-400" required></input>
                            <input name="lastName" type="text" placeholder='Prezime' onChange={handleStateChange} value={mailerState.lastName} className="mb-[20px] bg-white p-[10px] w-[49%] rounded sm:w-[100%] border-[1px] border-solid border-slate-400" required></input>
                        </div>
                        <input name="email" type="email" placeholder='Email' onChange={handleStateChange} value={mailerState.email} className="mb-[20px] bg-white p-[10px] rounded border-[1px] border-solid border-slate-400" required></input>
                        <input name="subject" type="text" placeholder='Subjekt' onChange={handleStateChange} value={mailerState.subject} className="mb-[20px] bg-white p-[10px] rounded border-[1px] border-solid border-slate-400" required></input>
                        <textarea name="message" placeholder='Vaša poruka' onChange={handleStateChange} value={mailerState.message} className="resize-none mb-[20px] bg-white h-[180px] p-[10px] rounded border-[1px] border-solid border-slate-400"></textarea>
                    </div>
                    <button type="submit" className="bg-cyan-600 hover:bg-cyan-900 w-[200px] p-[10px] text-white sm:w-[100%]">Pošalji</button>
                </form>
            </div>
               
            {
              isLoaded ? <div className="w-[50%] lg-helper:w-[100%]">
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={16}
                >
                  { /* Child components, such as markers, info windows, etc. */
                    <MarkerF position={{lat: 44.530049306736885, lng: 18.692964870199837}}>
                    </MarkerF>
                  }
                  <></>
                </GoogleMap>
                </div> : <></>
            }
              
            
        </m.div>   
    );
  
  }