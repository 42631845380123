import logo from '../../assets/logo/logo.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState, useRef, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { motion as m } from "framer-motion";

let offset = 0;
export default function Navigation() {

   
    const [hamburger, setHamburger] = useState(false);
    const [checkViewFromTop, setCheckViewFromTop] = useState(false);
    const mobileNav = useRef(null);
    
   


    useEffect(() => {
    
        function checkView() {
            
                if (offset > window.pageYOffset) {
                  setCheckViewFromTop(true);
                } else {
                  setCheckViewFromTop(false);
                }
            
                offset = window.pageYOffset;
              
        }
        
        window.addEventListener('scroll', checkView);
        
         
        let handler = (e) => {
            if (!mobileNav.current.contains(e.target)) {
                setHamburger(false);
            }
        };

        document.addEventListener("mousedown", handler);

        return () =>{
            document.removeEventListener("mousedown", handler);
            window.removeEventListener('scroll', checkView);
        }
    }, [])

    return (
        <nav className={`flex px-[150px] items-center sticky top-0 justify-between bg-black md:relative xl:px-[100px] md:px-[75px] transition-all ease-in z-20 sm:p-2 sm:sticky sm:top-0 ${checkViewFromTop ? " sticky top-0 sm:sticky sm:top-0" : "top-[-100px] sm:top-[-100px]"}`}>
            <div className="w-[80px]">
                <NavLink to="/"><img src={logo} className="w-[100%]"/></NavLink>
            </div>
            <div className="relative">
                <ul className="flex md:hidden">
                    <NavLink to="/"  className="text-slate-100 ml-[30px] md:mb-[15px] hover:text-slate-300"><li>Početna</li></NavLink>
                    <NavLink to="/O nama" className="text-slate-100 ml-[30px] md:mb-[15px] hover:text-slate-300"><li>O nama</li></NavLink>
                    <NavLink to="/Projekti"  className="text-slate-100 ml-[30px] md:mb-[15px] hover:text-slate-300"><li>Arhitektura</li></NavLink>
                    <NavLink to="/Kontakt" className="text-slate-100 ml-[30px] md:mb-[15px] hover:text-slate-300"><li>Kontakt</li></NavLink>
                </ul>
            </div>
            <div ref={mobileNav} className="text-white hidden md:block">
                <FontAwesomeIcon icon="fa-bars" className="cursor-pointer text-2xl" onClick={() => setHamburger(!hamburger)}></FontAwesomeIcon>
                
                {
                    hamburger ? 
                    <m.div initial={{left: 500}} animate={{left:0}} 
                    transition={{duration: 0.2, ease: "easeInOut"}} className="relative md:absolute md:top-[85px] py-[20px] w-[100vw] md:bg-black mb:block z-10">
                    
                        <ul className="flex text-slate-200 md:flex-col">
                            <NavLink to="/" className="text-slate-100 pl-[75px] py-[20px] sm:pl-[50px]"><li onClick={() => setHamburger(!hamburger)}>Početna</li></NavLink>
                            <NavLink to="/O nama" className="text-slate-100 pl-[75px] py-[20px] sm:pl-[50px]"><li onClick={() => setHamburger(!hamburger)}> O nama</li></NavLink>
                            <NavLink to="/Projekti" className="text-slate-100 pl-[75px] py-[20px] sm:pl-[50px]"><li onClick={() => setHamburger(!hamburger)}>Arhitektura</li></NavLink>
                            <NavLink to="/Kontakt" className="text-slate-100 pl-[75px] py-[20px] sm:pl-[50px]"><li onClick={() => setHamburger(!hamburger)}>Kontakt</li></NavLink>
                        </ul>
                
                    </m.div> : null
                }
                    
                
            </div>
        </nav>
    )   
}