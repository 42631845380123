import { useState } from 'react';
import { useEffect } from 'react';

export default function Subscribe() {
    const [email, setEmail] = useState("");

    const [data, setData] = useState([]);

 // useEffect for get request test from NodeJS server only
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.code-design.ba/api/data');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    console.log(data);
  }, []); 


    const handleSubmit = async (e) => {
      e.preventDefault();
      console.log(email);
      try {
        const response = await fetch("https://api.code-design.ba/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        });
        if (response.ok) {
          alert("Successfully subscribed!");
          setEmail("");
        } else {
          alert("Failed to subscribe. Please try again later.");
        }
      } catch (error) {
        console.error(error);
      }
    };


    return (
        <section className="bg-subscribe-bg bg-cover bg-fixed bg-bottom bg-no-repeat p-[150px] h-[80vh] flex flex-col items-start justify-center sm:p-[20px]">
            <div>
                <h2 className="text-slate-200 mb-[15px] text-4xl">Pridružite se našoj mail list</h2>
                <p className="text-slate-200 mb-[70px] text-xl">Ne propustite najnovije vijesti iz arhitekture i naših budućih projekata</p>
                <form onSubmit={handleSubmit}>
                <div className="flex w-[100%]">
                    <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Vaš email" className="h-[45px] pl-[10px] w-[70%] border-[1px]" required/>
                    <button type="submit" className="h-[45px] bg-cyan-600 hover:bg-cyan-900 text-white w-[30%]">Pošalji</button>
                </div>
                </form>
            </div>
        </section>
    )
}