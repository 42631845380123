import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {

  
    return (
        <footer className="bg-black grid grid-cols-3 gap-20 text-white px-[150px] py-[80px] xl:gap-10 lg:gap-5 lg:grid-cols-1 xl:px-[100px]  md:px-[75px] sm:px-[20px]">
            <div>
                <h3 className="text-5xl mb-[10px] font-medium text-gray-200">CODE Design</h3>
                <p className="text-xl text-slate-200">Arhitektonski biro</p> 
            </div>
            <hr className="hidden lg:block border-slate-800 lg:my-[40px]"></hr>
            <div className="">
                <ul>
                    <li className="mb-[8px]"><span className="font-medium">Adresa:&nbsp; </span><p className="inline-block text-slate-200">75000, Stupine B-8, Tuzla</p></li>
                    <li className="mb-[8px]"><span className="font-medium">Država:&nbsp; </span><p className="inline-block text-slate-200">Bosna i Hercegovina</p></li>
                    <li className="mb-[8px]"><span className="font-medium">Registarski broj:&nbsp; </span><p className="inline-block text-slate-200">032-0-Reg-18-000718</p></li>
                    <li className="mb-[8px]"><span className="font-medium">ID/PDV Broj:&nbsp; </span><p className="inline-block text-slate-200">4210329050008/210329050008</p></li>
                    <li className="mb-[8px]"><span className="font-medium">Račun br:&nbsp; </span><p className="inline-block text-slate-200">1610000199740083 RAIFFEISEN BANKA DD</p></li>
                    <li className="mb-[8px]"><span className="font-medium">Devizni račun:&nbsp; </span><p className="inline-block text-slate-200">06000047152 RAIFFEISEN BANKA DD</p></li>
                    <li className="mb-[8px]"><span className="mr-[15px] text-xl"><FontAwesomeIcon icon="phone"></FontAwesomeIcon></span><p className="inline-block text-slate-200">+387 35 248 440 ili + 387 61 078 792</p></li>
                    <li><span className="mr-[15px] text-xl"><FontAwesomeIcon icon="envelope"></FontAwesomeIcon></span><p className="inline-block text-slate-200">info@code-design.ba</p></li>
                </ul>
            </div>
            <hr className="hidden lg:block border-slate-800 lg:my-[40px]"></hr>
            <div>
                <ul className="flex mb-[20px]">
                    <li className="mr-[20px] text-2xl"><a href="https://www.linkedin.com/company/codedesigndoo/" target="_blank"><FontAwesomeIcon icon={['fab','linkedin']}></FontAwesomeIcon></a></li>
                    <li className="text-2xl"><a href="https://www.facebook.com/code.design.tuzla" target="_blank"><FontAwesomeIcon icon={['fab', 'facebook']}></FontAwesomeIcon></a></li>
                </ul>
                <div>
                    <p className="text-slate-200">@2023 Sva prava pridržana</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;