import { useState, } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation,} from 'swiper';
import { motion as m } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Project(props) {

    
    const [showCarousel, setCarousel] = useState(false);
    const [showOverlay, setOverlay] = useState(
        false
    );
    let boxVariants = {};
    const isMobile = window.innerWidth < 768;
    


    function openModal() {
        setCarousel(true);
        document.body.style.overflow = 'hidden';

    }

    function closeModal() {
        setCarousel(false);
        document.body.style.overflow= 'unset';
    }

    
    

    if (!isMobile) {
        boxVariants = {
          initial: {
            opacity: 0
          },
          whileHover: {
            opacity: 1
          },
          transition: {
            duration: 0.25
          }
        };
    }

    
    return (
        <>
        <m.div initial={{opacity: 0}} animate={{opacity: 1}} 
        transition={{duration: 0.75, ease: "easeOut"}} className="relative z-5 cursor-pointer" onClick={() => openModal()} onMouseEnter={() => setOverlay(true)} onMouseLeave={() => setOverlay(false)}>
            <img className="w-[100%]  aspect-[1/1] object-cover" src={props.background} alt={props.alt}/>
            <m.div  variants={boxVariants} className={`absolute top-0 left-0 bottom-0 right-0 bg-slate-300 w-[100%] bg-opacity-70 justify-center items-center ${(showOverlay) ? "flex" :"hidden"} md:flex md:bg-opacity-60 md:backdrop-blur-none md:bg-slate-400 md:font-semibold md:text-xs`}>
                <div className="max-w-[70%] bg-gray-100 p-2 rounded-md text-slate-700">
                    <p className="font-medium text-lg mb-2">{ props.description }</p>
                    <p><span className="font-medium">Lokacija:</span> { props.location }</p>
                    <p><span className="font-medium">Godina:</span> { props.year }</p>
                </div>
            </m.div>
        </m.div>
        <div className={`w-[100%] flex justify-center items-center  top-0 left-0 h-[100%] bg-black fixed z-20 overflow-y-hidden overflow-x-hidden ${(showCarousel ? "block" : "hidden")}`}>
            <div className="absolute left-5 top-5 text-white z-10 md:w-[80%]">
                <p className="text-2xl font-medium mb-2">{props.description}</p>
                <p><span className="font-medium">Lokacija:&nbsp;</span><span className="text-slate-200"> {props.location}</span></p>
                <p><span className="font-medium">Godina:&nbsp;</span><span className="text-slate-200">{props.year}</span></p>
            </div>
            <FontAwesomeIcon icon="xmark" className="text-white hover:text-slate-200 absolute text-3xl cursor-pointer right-5 top-5 z-30" onClick={() => closeModal()}></FontAwesomeIcon>
            
            <Swiper
                modules={[Navigation, ]}
                spaceBetween={50}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
                navigation
            >
                
                {
                props.gallery.map((gall) => {
                    console.log(gall)
                    return (
                        <SwiperSlide>
                            <div className="h-[80vh] text-center md:w-[100%] cursor-pointer clear-right" >
                                <img className="h-[100%] inline-block object-contain" src={gall} alt={props.alt}/>
                            </div>
                        </SwiperSlide>
                        
                        
                    )
                })
                }
            
            </Swiper>
            
        </div>

        
        </>
    )
}