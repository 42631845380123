/* Dzamija */

import dzamija1 from '../projects/Dzamija/12_.jpg';
import dzamija2 from '../projects/Dzamija/22_.jpg';
import dzamija3 from '../projects/Dzamija/23_.jpg';
import dzamija4 from '../projects/Dzamija/42_.jpg';
import dzamija5 from '../projects/Dzamija/43_.jpg';

/* HezZgrada */

import hezZgrada1 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera.jpg';
import hezZgrada2 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera_2.jpg';
import hezZgrada3 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera_4.jpg';
import hezZgrada4 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera_5.jpg';
import hezZgrada5 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera_6.jpg';
import hezZgrada6 from '../projects/HezZgrada/HEZZ_FINAL_ACCamera_10.jpg';
import hezZgrada7 from '../projects/HezZgrada/HEZZ_FINAL_Parallel View.jpg';
import hezZgrada8 from '../projects/HezZgrada/HEZZ_FINAL_Parallel View_1.jpg';
import hezZgrada9 from '../projects/HezZgrada/HEZZ_FINAL_Parallel View_2.jpg';
import hezZgrada10 from '../projects/HezZgrada/HEZZ_FINAL_Parallel View_3.jpg';
import hezZgrada11 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera.jpg';
import hezZgrada12 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_1.jpg';
import hezZgrada13 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_3.jpg';
import hezZgrada14 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_4.jpg';
import hezZgrada15 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_5.jpg';
import hezZgrada16 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_6.jpg';
import hezZgrada17 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_7.jpg';
import hezZgrada18 from '../projects/HezZgrada/Jasmin stupineFINALapril2021_ACCamera_8.jpg';
import hezZgrada19 from '../projects/HezZgrada/Istocna fasada.jpg';
import hezZgrada20 from '../projects/HezZgrada/Juzna fasada.jpg';
import hezZgrada21 from '../projects/HezZgrada/Sjeverna fasaada.jpg';
import hezZgrada22 from '../projects/HezZgrada/Zapadna fasada.jpg';


/* Kuca */

import kuca1 from '../projects/Kuca/ACCamera_1.jpg';
import kuca2 from '../projects/Kuca/ACCamera_7.jpg';
import kuca3 from '../projects/Kuca/ACCamera_8.jpg';
import kuca4 from '../projects/Kuca/ACCamera_9.jpg';

/* Kuca 2 */

import kucaMula1 from '../projects/Kuca2/Mula-skver-3D_ACCamera.jpg';
import kucaMula2 from '../projects/Kuca2/Mula-skver-3D_ACCamera_2.jpg';
import kucaMula3 from '../projects/Kuca2/Mula-skver-3D_ACCamera_3.jpg';
import kucaMula4 from '../projects/Kuca2/Mula-skver-3D_ACCamera_6.jpg';

/* Zgrada kod suda */

import zgradaKodSuda1 from '../projects/ZgradaKodSuda/unnamed.jpg';
import zgradaKodSuda2 from '../projects/ZgradaKodSuda/unnamed1.jpg';
import zgradaKodSuda3 from '../projects/ZgradaKodSuda/unnamed2.jpg';
import zgradaKodSuda4 from '../projects/ZgradaKodSuda/unnamed3.jpg';

/* Zgrada sjenjak */

import zgradaSjenjak1 from '../projects/ZgradaSjenjak/deling-3d_ACCamera_1.jpg';
import zgradaSjenjak2 from '../projects/ZgradaSjenjak/deling-3d_ACCamera_2.jpg';
import zgradaSjenjak3 from '../projects/ZgradaSjenjak/deling-3d_ACCamera_3.jpg';

/* Hostel Tuzla - Azur Ibricic */

import HostelTuzlaAzurIbricic1 from '../projects/Hostel Tuzla - Azur Ibricic/ACCamera.jpg';
import HostelTuzlaAzurIbricic2 from '../projects/Hostel Tuzla - Azur Ibricic/ACCamera_1.jpg';
import HostelTuzlaAzurIbricic3 from '../projects/Hostel Tuzla - Azur Ibricic/ACCamera_4.jpg';
import HostelTuzlaAzurIbricic4 from '../projects/Hostel Tuzla - Azur Ibricic/ACCamera_5.jpg';
import HostelTuzlaAzurIbricic5 from '../projects/Hostel Tuzla - Azur Ibricic/ACCamera_6.jpg';

/* Hotel Sutivan Brac */

import HotelSutivanBrac1 from '../projects/Hotel Sutivan Brac/ACCamera.jpg';
import HotelSutivanBrac2 from '../projects/Hotel Sutivan Brac/ACCamera_1.jpg';
import HotelSutivanBrac3 from '../projects/Hotel Sutivan Brac/ACCamera_2.jpg';
import HotelSutivanBrac4 from '../projects/Hotel Sutivan Brac/ACCamera_5.jpg';

/* Individualni stambeni objekat Ivanjica */

import StambeniObjekatIvanica1 from '../projects/Individualni stambeni objekta Ivanjica/edit-02 b.jpg';
import StambeniObjekatIvanica2 from '../projects/Individualni stambeni objekta Ivanjica/edit-02 p.jpg';
import StambeniObjekatIvanica3 from '../projects/Individualni stambeni objekta Ivanjica/edit-03.jpg';
import StambeniObjekatIvanica4 from '../projects/Individualni stambeni objekta Ivanjica/edit-04 b p.jpg';
import StambeniObjekatIvanica5 from '../projects/Individualni stambeni objekta Ivanjica/edit-04 b.jpg';

/* Kolektivni stambeni objekat Hezz Lukavac */

import HezzLukavac1 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera.jpg';
import HezzLukavac2 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera1.jpg';
import HezzLukavac3 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_1.jpg';
import HezzLukavac4 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_11.jpg';
import HezzLukavac5 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_2.jpg';
import HezzLukavac6 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_3.jpg';
import HezzLukavac7 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_4.jpg';
import HezzLukavac8 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_5.jpg';
import HezzLukavac9 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_6.jpg';
import HezzLukavac10 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_IIv1.jpg';
import HezzLukavac11 from '../projects/Kolektivni Stambeni objekat Heez Lukavac/ACCamera_IIv_2.jpg';

/* Poslovni objekat Tuzla */

import PoslovniObjekatTuzla1 from '../projects/Poslovni objekat Tuzla/ACCamera_1a.jpg';
import PoslovniObjekatTuzla2 from '../projects/Poslovni objekat Tuzla/ACCamera_2a.jpg';
import PoslovniObjekatTuzla3 from '../projects/Poslovni objekat Tuzla/ACCamera_3a.jpg';
import PoslovniObjekatTuzla4 from '../projects/Poslovni objekat Tuzla/ACCamera_4a.jpg';
import PoslovniObjekatTuzla5 from '../projects/Poslovni objekat Tuzla/ACCamera_5a.jpg';

/* Stadion FK Gradina Srebrenik */

import StadionSrebenik1 from '../projects/Stadion FK Gradina Srebrenik/ACCamera.jpg';
import StadionSrebenik2 from '../projects/Stadion FK Gradina Srebrenik/ACCamera0.jpg';
import StadionSrebenik3 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_1.jpg';
import StadionSrebenik4 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_3.jpg';
import StadionSrebenik5 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_4.jpg';
import StadionSrebenik6 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_6.jpg';
import StadionSrebenik7 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_7.jpg';
import StadionSrebenik8 from '../projects/Stadion FK Gradina Srebrenik/ACCamera_8.jpg';

/* Stambeni kompleks Hezz Si Selo */

import HezzSiSelo1 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_4.jpg';
import HezzSiSelo2 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_5.jpg';
import HezzSiSelo3 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_10.jpg';
import HezzSiSelo4 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_11.jpg';
import HezzSiSelo5 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_12.jpg';
import HezzSiSelo6 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_13.jpg';
import HezzSiSelo7 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_14.jpg';
import HezzSiSelo8 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_15.jpg';
import HezzSiSelo9 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_16.jpg';
import HezzSiSelo10 from '../projects/Stambeni kompleks Heez Si Selo/ACCamera_17.jpg';

/* Stomatoloska ordinacija Alabajci */

import OrdinacijaAlabajci1 from '../projects/Stomatoloska ordinacija Alabajci/Ordinacija AC_2_ACCamera_2.jpg';
import OrdinacijaAlabajci2 from '../projects/Stomatoloska ordinacija Alabajci/Ordinacija AC_2_ACCamera_3.jpg';
import OrdinacijaAlabajci3 from '../projects/Stomatoloska ordinacija Alabajci/Ordinacija AC_2_ACCamera_4.jpg';
import OrdinacijaAlabajci4 from '../projects/Stomatoloska ordinacija Alabajci/Ordinacija AC_2_ACCamera_5.jpg';

/* Urbana vila Amir Meskovic */

import UrbanaVilaAM1 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera.jpg';
import UrbanaVilaAM2 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera_1.jpg';
import UrbanaVilaAM3 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera_2.jpg';
import UrbanaVilaAM4 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera_7.jpg';
import UrbanaVilaAM5 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera_8.jpg';
import UrbanaVilaAM6 from '../projects/Urbana Vila Tuzla - Amir Meskovic/ACCamera_9.jpg';

/* Urbana vila Senad A */

import UrbanaVilaSA1 from '../projects/Urbana Vila Tuzla - Senad A/ACCamera.jpg';
import UrbanaVilaSA2 from '../projects/Urbana Vila Tuzla - Senad A/ACCamera_1.jpg';
import UrbanaVilaSA3 from '../projects/Urbana Vila Tuzla - Senad A/ACCamera_2.jpg';
import UrbanaVilaSA4 from '../projects/Urbana Vila Tuzla - Senad A/ACCamera_5.jpg';
import UrbanaVilaSA5 from '../projects/Urbana Vila Tuzla - Senad A/ACCamera_6.jpg';

/* Uredjenje enterijera Tuzla - Stan 1 */

import EnterijerStan1 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_6.jpg';
import EnterijerStan2 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_8.jpg';
import EnterijerStan3 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_15.jpg';
import EnterijerStan4 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_15a.jpg';
import EnterijerStan5 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_16.jpg';
import EnterijerStan6 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_18.jpg';
import EnterijerStan7 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_20.jpg';
import EnterijerStan8 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_22.jpg';
import EnterijerStan9 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_2AC.jpg';
import EnterijerStan10 from '../projects/Uredjenje Emnterijera Tuzla - Stan 1/ACCamera_3asdas.jpg';

/* Uredjenje enterijera knjigovodstveni servis - Minesu Tuzla */

import KnjigovodstveniServisTz1 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_1.jpg';
import KnjigovodstveniServisTz2 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_3.jpg';
import KnjigovodstveniServisTz3 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_5.jpg';
import KnjigovodstveniServisTz4 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_6.jpg';
import KnjigovodstveniServisTz5 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_8.jpg';
import KnjigovodstveniServisTz6 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_10.jpg';
import KnjigovodstveniServisTz7 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_12.jpg';
import KnjigovodstveniServisTz8 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_13.jpg';
import KnjigovodstveniServisTz9 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_14.jpg';
import KnjigovodstveniServisTz10 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_15.jpg';
import KnjigovodstveniServisTz11 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_16.jpg';
import KnjigovodstveniServisTz12 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_17.jpg';
import KnjigovodstveniServisTz13 from '../projects/Uredjenje Enterijera - Knjigovodstveni servis Tuzla/ACCamera_18.jpg';

/* Uredjenje ugostiteljskog objekta brze prehrane Tuzla */

import UgostiteljskiObjekatBrzPrehraneTz1 from '../projects/Uredjenje Enterijera - Ugostiteljski objekat Tuzla/ACCamera_2.jpg';
import UgostiteljskiObjekatBrzPrehraneTz2 from '../projects/Uredjenje Enterijera - Ugostiteljski objekat Tuzla/ACCamera_3.jpg';
import UgostiteljskiObjekatBrzPrehraneTz3 from '../projects/Uredjenje Enterijera - Ugostiteljski objekat Tuzla/IMG_2965.jpg';
import UgostiteljskiObjekatBrzPrehraneTz4 from '../projects/Uredjenje Enterijera - Ugostiteljski objekat Tuzla/IMG_2968.jpg';

/* Uredjenje enterijera vikendica Srebrenik */

import VikendicaSrebrenik1 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera_2.jpg';
import VikendicaSrebrenik2 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera_3.jpg';
import VikendicaSrebrenik3 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera_4.jpg';
import VikendicaSrebrenik4 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera_5.jpg';
import VikendicaSrebrenik5 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera6.jpg';
import VikendicaSrebrenik6 from '../projects/Uredjenje Enterijera - VIkendica Srebrenik/ACCamera_7.jpg';


/* Uredjenje enterijera Istocno Sarajevo Stan 1 */

import IstocnoSaraStan1 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera.jpg';
import IstocnoSaraStan2 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_1.jpg';
import IstocnoSaraStan3 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_2.jpg';
import IstocnoSaraStan4 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_3.jpg';
import IstocnoSaraStan5 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_4.jpg';
import IstocnoSaraStan6 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_5.jpg';
import IstocnoSaraStan7 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_6.jpg';
import IstocnoSaraStan8 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_7.jpg';
import IstocnoSaraStan9 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 1/ACCamera_8.jpg';

/* Uredjenje enterijera Istocno Sarajevo Stan 2 */

import IstocnoSara2Stan1 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCameraA.jpg';
import IstocnoSara2Stan2 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_1A.jpg';
import IstocnoSara2Stan3 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_2.jpg';
import IstocnoSara2Stan4 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_3A.jpg';
import IstocnoSara2Stan5 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_4.jpg';
import IstocnoSara2Stan6 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_A1.jpg';
import IstocnoSara2Stan7 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_B1.jpg';
import IstocnoSara2Stan8 from '../projects/Uredjenje Enterijera Istocno Sarajevo - Stan 2/ACCamera_C3.jpg';

/* Uredjenje projekta ljetna basta Istocno Sarajevo */

import LjetnaBastaISarajevo1 from '../projects/Uredjenje projekta ljetna basta Istocno Sarajevo/ACCamera_1.jpg';
import LjetnaBastaISarajevo2 from '../projects/Uredjenje projekta ljetna basta Istocno Sarajevo/ACCamera_2-1.jpg';
import LjetnaBastaISarajevo3 from '../projects/Uredjenje projekta ljetna basta Istocno Sarajevo/ACCamera_3-1.jpg';
import LjetnaBastaISarajevo4 from '../projects/Uredjenje projekta ljetna basta Istocno Sarajevo/ACCamera_4-1.jpg';






let projectsDEMO = [
    {
        'background':dzamija1,
        'gallery': [
            
                dzamija1,
                dzamija2,
                dzamija3,
                dzamija4,
                dzamija5
            
        ],
        'description': 'Idejni projekat vjerskog objekta',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Idejni projekat vjerskog objekta'
    },
    {
        'background':hezZgrada1,
        'gallery': [
            hezZgrada1,
            hezZgrada2,
            hezZgrada3,
            hezZgrada4,
            hezZgrada5,
            hezZgrada6,
            hezZgrada7,
            hezZgrada8,
            hezZgrada9,
            hezZgrada10,
            hezZgrada11,
            hezZgrada12,
            hezZgrada13,
            hezZgrada14,
            hezZgrada15,
            hezZgrada16,
            hezZgrada17,
            hezZgrada18,
            hezZgrada19,
            hezZgrada20,
            hezZgrada21,
            hezZgrada22
        ],
        'description': 'Stambeno poslovni objekat –  Idejni i glavni projekat stambeno poslovnog objekta',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Stambeno poslovni objekat – Idejni i glavni projekat stambeno poslovnog objekta'
    },
    {
        'background':  kuca1,
        'gallery': [
            kuca1,
            kuca2,
            kuca3,
            kuca4
        ],
        'description': 'Individualni stambeni objekat. Idejni i glavni projekat individualnog stambenog objekta',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Individualni stambeni objekat. Idejni i glavni projekat individualnog stambenog objekta'
    },
    {
        'background': kucaMula1,
        'gallery': [
            kucaMula1,
        kucaMula2,
        kucaMula3,
        kucaMula4
        ],
        'description': 'Individualni stambeni objekat. Idejni i glavni projekat individualnog stambenog objekta',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Individualni stambeni objekat. Idejni i glavni projekat individualnog stambenog objekta'
    },
    {
        'background': zgradaKodSuda1,
        'gallery': [
            zgradaKodSuda1,
            zgradaKodSuda2,
            zgradaKodSuda3,
            zgradaKodSuda4
        ],
        'description': 'Kolektivni stambeni objekat. Idejni i glavni projekat dogradnje kolektivnog stambenog objekta.',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Kolektivni stambeni objekat. Idejni i glavni Projekat dogradnje kolektivnog stambenog objekta.'
    },
    {
        'background': zgradaSjenjak1,
        'gallery': [
            zgradaSjenjak1,
            zgradaSjenjak2,
            zgradaSjenjak3
        ],
        'description': 'Idejno – konceptualno riješenje stambeno poslovbog objekta – Konkurs',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Idejno – konceptualno riješenje stambeno poslovbog objekta – Konkurs'
    },
    {
        'background': HostelTuzlaAzurIbricic1,
        'gallery': [
            HostelTuzlaAzurIbricic1,
            HostelTuzlaAzurIbricic2,
            HostelTuzlaAzurIbricic3,
            HostelTuzlaAzurIbricic4,
            HostelTuzlaAzurIbricic5
        ],
        'description': 'Idejni projekat – Hostel',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Idejni projekat – Hostel'
    },
    {
        'background': HotelSutivanBrac1,
        'gallery': [
            HotelSutivanBrac1,
            HotelSutivanBrac2,
            HotelSutivanBrac3,
            HotelSutivanBrac4,
        ],
        'description': 'Idejni projekat stambeno poslovnog objekta – Motel',
        'location': 'Otok Brać',
        'year': '2018',
        'alt': 'Idejni projekat – Motel Brać'
    },
    {
        'background': StambeniObjekatIvanica1,
        'gallery': [
            StambeniObjekatIvanica1,
            StambeniObjekatIvanica2,
            StambeniObjekatIvanica3,
            StambeniObjekatIvanica4,
            StambeniObjekatIvanica5
        ],
        'description': 'Individualni stambeni objekat. Idejni i glavni projekat individulanog stambenog objekta',
        'location': 'Ivanjica,Ravno,BiH',
        'year': '2019',
        'alt': 'Idejni projekat – stambeni objekat Ivanjica'
    },
    {
        'background': HezzLukavac1,
        'gallery': [
            HezzLukavac1,
            HezzLukavac2,
            HezzLukavac3,
            HezzLukavac4,
            HezzLukavac5,
            HezzLukavac6,
            HezzLukavac7,
            HezzLukavac8,
            HezzLukavac9,
            HezzLukavac10,
            HezzLukavac11
        ],
        'description': 'Idejni projekat kolektivnog stambenog objekta Lukavac',
        'location': 'Lukavac',
        'year': '2022',
        'alt': 'Idejni projekat – stambeni objekat Lukavac'
    },
    {
        'background': PoslovniObjekatTuzla1,
        'gallery': [
            PoslovniObjekatTuzla1,
            PoslovniObjekatTuzla2,
            PoslovniObjekatTuzla3,
            PoslovniObjekatTuzla4,
            PoslovniObjekatTuzla5
        ],
        'description': 'Idejni projekat – poslovni objekat',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Idejni projekat – poslovni objekat'
    },
    {
        'background': StadionSrebenik1,
        'gallery': [
            StadionSrebenik1,
            StadionSrebenik2,
            StadionSrebenik3,
            StadionSrebenik4,
            StadionSrebenik5,
            StadionSrebenik6,
            StadionSrebenik7,
            StadionSrebenik8
        ],
        'description': 'Idejni projekat zapadne tribine FK Gradina Srebrenik',
        'location': 'Srebrenik',
        'year': '2022',
        'alt': 'Idejni projekat – zapadna tribina FK Gradina Srebrenik'
    },
    {
        'background': HezzSiSelo1,
        'gallery': [
            HezzSiSelo1,
            HezzSiSelo2,
            HezzSiSelo3,
            HezzSiSelo4,
            HezzSiSelo5,
            HezzSiSelo6,
            HezzSiSelo7,
            HezzSiSelo8,
            HezzSiSelo9,
            HezzSiSelo10
        ],
        'description': 'Stambeni kompleks Hezz gradnja Ši Selo',
        'location': 'Tuzla',
        'year': '2021',
        'alt': 'Stambeni kompleks Hezz gradnja Ši Selo'
    },
    {
        'background': OrdinacijaAlabajci1,
        'gallery': [
            OrdinacijaAlabajci1,
            OrdinacijaAlabajci2,
            OrdinacijaAlabajci3,
            OrdinacijaAlabajci4,
        ],
        'description': 'Stomatološka ordinacija Alabajči',
        'location': 'Tuzla',
        'year': '2019',
        'alt': 'Stomatološka ordinacija Alabajči'
    },
    {
        'background': UrbanaVilaAM1,
        'gallery': [
            UrbanaVilaAM1,
            UrbanaVilaAM2,
            UrbanaVilaAM3,
            UrbanaVilaAM4,
            UrbanaVilaAM5,
            UrbanaVilaAM6
        ],
        'description': 'Urbana vila Amir Mešković',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Urbana vila Amir Mešković'
    },
    {
        'background': UrbanaVilaSA1,
        'gallery': [
            UrbanaVilaSA1,
            UrbanaVilaSA2,
            UrbanaVilaSA3,
            UrbanaVilaSA4,
            UrbanaVilaSA5
        ],
        'description': 'Urbana vila Senad A',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Urbana vila Senad A'
    },
    {
        'background': EnterijerStan1,
        'gallery': [
            EnterijerStan1,
            EnterijerStan2,
            EnterijerStan3,
            EnterijerStan4,
            EnterijerStan5,
            EnterijerStan6,
            EnterijerStan7,
            EnterijerStan8,
            EnterijerStan9,
            EnterijerStan10
        ],
        'description': 'Idejni projekat uređenja enterijera Tuzla - Stan 1',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Idejni projekat enterijera - stan Tuzla'
    },
    {
        'background': KnjigovodstveniServisTz1,
        'gallery': [
            KnjigovodstveniServisTz1,
            KnjigovodstveniServisTz2,
            KnjigovodstveniServisTz3,
            KnjigovodstveniServisTz4,
            KnjigovodstveniServisTz5,
            KnjigovodstveniServisTz6,
            KnjigovodstveniServisTz7,
            KnjigovodstveniServisTz8,
            KnjigovodstveniServisTz9,
            KnjigovodstveniServisTz10,
            KnjigovodstveniServisTz11,
            KnjigovodstveniServisTz12,
            KnjigovodstveniServisTz13
        ],
        'description': 'Uređenje enterijera knjigovodstveni servis - Minesu Tuzla',
        'location': 'Tuzla',
        'year': '2018',
        'alt': 'Uređenje enterijera knjigovodstveni servis - Minesu Tuzla'
    },
    {
        'background': UgostiteljskiObjekatBrzPrehraneTz1,
        'gallery': [
            UgostiteljskiObjekatBrzPrehraneTz1,
            UgostiteljskiObjekatBrzPrehraneTz2,
            UgostiteljskiObjekatBrzPrehraneTz3,
            UgostiteljskiObjekatBrzPrehraneTz4,
        ],
        'description': 'Projekat enterijera ugostiteljskog objekta brze prehrane - Tuzla',
        'location': 'Tuzla',
        'year': '2017',
        'alt': 'Projekat enterijera ugostiteljskog objekta brze prehrane - Tuzla'
    },
    {
        'background': VikendicaSrebrenik1,
        'gallery': [
            VikendicaSrebrenik1,
            VikendicaSrebrenik2,
            VikendicaSrebrenik3,
            VikendicaSrebrenik4,
            VikendicaSrebrenik5,
            VikendicaSrebrenik6
        ],
        'description': 'Uređenje enterijera vikendice - Srebrenik',
        'location': 'Srebrenik',
        'year': '2018',
        'alt': 'Uređenje enterijera vikendice - Srebrenik'
    },
    {
        'background': IstocnoSaraStan1,
        'gallery': [
            IstocnoSaraStan1,
            IstocnoSaraStan2,
            IstocnoSaraStan3,
            IstocnoSaraStan4,
            IstocnoSaraStan5,
            IstocnoSaraStan6,
            IstocnoSaraStan7,
            IstocnoSaraStan8,
            IstocnoSaraStan9
        ],
        'description': 'Uređenje enterijera stana 1 - Istočno Sarajevo',
        'location': 'Istočno Sarajevo',
        'year': '2018',
        'alt': 'Uređenje enterijera stana 1 - Istočno Sarajevo'
    },
    {
        'background': IstocnoSara2Stan1,
        'gallery': [
            IstocnoSara2Stan1,
            IstocnoSara2Stan2,
            IstocnoSara2Stan3,
            IstocnoSara2Stan4,
            IstocnoSara2Stan5,
            IstocnoSara2Stan6,
            IstocnoSara2Stan7,
            IstocnoSara2Stan8,
        ],
        'description': 'Uređenje enterijera stana 2 - Istočno Sarajevo',
        'location': 'Istocno Sarajevo',
        'year': '2019',
        'alt': 'Uređenje enterijera stana 2 - Istocno Sarajevo'
    },
    {
        'background': LjetnaBastaISarajevo1,
        'gallery': [
            LjetnaBastaISarajevo1,
            LjetnaBastaISarajevo2,
            LjetnaBastaISarajevo3,
            LjetnaBastaISarajevo4
        ],
        'description': 'Projekat uređenja ljetna bašta - Istočno Sarajevo',
        'location': 'Istočno Sarajevo',
        'year': '2019',
        'alt': 'Projekat uređenja ljetna bašta - Istočno Sarajevo'
    },
]

export default projectsDEMO;




